import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { DataService } from '../data.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { SocketioService } from '../socketio.service';
import { throws } from 'assert';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  constructor(private router:Router, private route: ActivatedRoute, private dataService: DataService, private formBuilder: FormBuilder, private socketService: SocketioService, private ref: ChangeDetectorRef) { 
      this.router.routeReuseStrategy.shouldReuseRoute = function() {
          return false;
      };

    this.changeDetectorRef = ref;
  }

  sidebar_toggle: boolean = false;

  data:any = {status:0, show_payment_alert:0};
  newLang:any = "";
  patientLanguages:any = [];
  patientTreatments:any = [];
  key = null;
  role_id = '0';
  billing:any = false;
  notifications:any = {status:0, notifications:[]};
  subscriptionStatus:any = {show:false, title:"", content:""}
  billForm: FormGroup;
  billItems: FormArray;
  runNotifs:any;
  nextReg="";
  menu = [];
  screenshotData:any;
  screenshotBlob:any;
  orgId = 0;
  verified = 2;
  orgInfo:any = {ac:{name:""}};
  regExpand = 0;
  selectedBranch:any = {id:0, name:""};
  idLocal:any = ["", "Aadhaar card", "Voters ID", "PAN Card", "Driving license","Passport"];
  idAe:any = ["", "Emirates ID", "Passport"];
  ids:any = [];
  billingPatient:any = {patient_id:0, reg_no:"", patient_name:""};
  regAge:any = "";
  newMessage: string;
  messageList: string[] = [];
  enqTags:any = [];
  patientRefby:any = "";

  //ID upload
  uploadForm: FormGroup;
  upFiles:any = [];
  files:any = {data:[]};

  socket;

  session:OT.Session;
  streams: Array<OT.Stream> = [];
  changeDetectorRef: ChangeDetectorRef;
  container: HTMLElement; 

  sender = [];
  caller = [];
  callStatus = 0;
  callType = "";
  callFromUser = 0;
  ecats:any = [];
  isSchedulePage:any = 0;

  edPatient:any = {status:0, tags:[], treatment:"", languages:[]};
  newAppPatient:any = 0;
  repeatType = 0;
	repeatCount = 2;
  defaultDuration:any = 15;
  min_hr:any = "minutes";
  currentUrl:any = "";
  nationality_nabidh:"";
  ngOnInit() {
    
    this.getCookie();

    if(window.location.toString().includes('schedule-flex')){
      this.isSchedulePage = 1;
    } else {
      this.isSchedulePage = 0;
    }
    
    this.currentUrl = window.location.href;
    
    //videocall
    //this.connectSocket();

    //load key from localStrorage
    this.key = localStorage.getItem('key');
    this.role_id = localStorage.getItem('role_id');
    //this.dataService.localData.billing = localStorage.getItem("billing");

    if(localStorage.getItem("branch") !== null){
      this.selectedBranch = JSON.parse( localStorage.getItem("branch"));
      
    }
    
    
    if(this.key == null) {
      window.location.href = "./login";
    }


  	this.dataService.headBar().subscribe( (data: any )=> {

      //@ts-ignore
      if (data.status == 0) {
        this.data = data;
        //console.log(this.data.show_payment_alert);
        localStorage.setItem('allow_edit', data.allow_edit);
        localStorage.setItem('user_id', data.user_id);
        localStorage.setItem('department', data.department);
        this.dataService.localData.notesVisible = data.notes_visible;
        this.dataService.orgMeta = data.orgMeta;
        this.dataService.localData.isNabidh = data.is_nabidh;
        localStorage.setItem("gstBilling", data.gstBilling);
        this.dataService.localData.gstBilling = data.gstBilling;
        this.dataService.localData.report_generator = data.report_generator;
        this.dataService.localData.allow_subscription = data.allow_subscription;
      } else {
        alert(data.msg);
      }

      
    } );

    this.loadSubscriptionStatus();
    this.loadNotifications();
    this.loadOrg();
    this.loadMenu();
    this.loadEnquiryCategories();
    //this.runNotifs = window.setInterval(() => this.loadNotifications(), 60000);

    
    this.billForm = this.formBuilder.group({
        item: '',
        rate: '',
        qty: '',
        amount:0,
        billItems:this.formBuilder.array([this.createBillItem()])
    });

    this.uploadForm = this.formBuilder.group({
      doc: [''],
      file_title: "ID",
    });

    //datepicker
    
    $( function() {
      (<any>$(".date-picker" )).datepicker({
          dateFormat: "dd-mm-yy",
          beforeShow: function() {
              setTimeout(function(){
                  $('.ui-datepicker').css('z-index', 99999999999999);
              }, 0);
          },
          onSelect: function (dateText, inst) {
          this.focus();
          }
      });
    });

    //capture screenshot
   let context = this;
   $('body').on('click', '#capture, #capture2, #capture3', function(){
     //@ts-ignore
    html2canvas(document.querySelector("body"), {

    }).then(canvas => {
        //document.body.appendChild(canvas);
        $('#screenshot').html(canvas);
        $('#screenshot canvas').css('width', '700px');
        $('#screenshot canvas').css('height', 'auto');
        $('#modal-report-issue').modal('show');

        context.screenshotData = canvas.toDataURL("image/png");
        
        
      });
    });
    
  //capture screenshot ends

  //New appointment - dashboard
  $('body').on('focus', '#inputNameApp', function(){
    let moi = $(this);
    
    
    //@ts-ignore
    moi.autocomplete({
      appendTo: '#addDashAppointment',
      source: function(request, response) {
          $.ajax({
            url:context.dataService.REST_API_SERVER + 'patient/autocomplete',
            type:'GET',
            dataType:'json',
            data: {term: request.term, branch:0},
            headers: {key:localStorage.getItem('key')},
            success: function(responseData) {
              
              var array = responseData.map(function(element){
                return {value: element['value'] , id: element['patient_id'], name: element['patient_name']};
              });
              response(array);
  
            }
          })
        }, 
        select: function(event, ui) {
            moi.val(ui.item.name);
            context.newAppPatient = ui.item.id;
            return false;
        }
    });
  });

  //Autocomplete nationality
  $('body').on('focus', '#inputNationality', function(){
    let moi = $(this);
    
    
    //@ts-ignore
    moi.autocomplete({
      appendTo: '#addPatient',
      source: function(request, response) {
          $.ajax({
            url:context.dataService.REST_API_SERVER + 'hl7/country_ac',
            type:'GET',
            dataType:'json',
            data: {term: request.term},
            headers: {key:localStorage.getItem('key')},
            success: function(responseData) {
              
              var array = responseData.map(function(element){
                return {value: element['nationality'] , nationality: element['nationality'], country: element['country'], id: element['id']};
              });
              response(array);
  
            }
          })
        }, 
        select: function(event, ui) {
            moi.val(ui.item.nationality);
            console.log(ui.item);
            context.nationality_nabidh = ui.item.nationality;
            return false;
        }
    });
  });

  //Autocomplete nationality
  $('body').on('focus', '#inputEditNationality', function(){
    let moi = $(this);
    
    
    //@ts-ignore
    moi.autocomplete({
      appendTo: '#editPatient',
      source: function(request, response) {
          $.ajax({
            url:context.dataService.REST_API_SERVER + 'hl7/country_ac',
            type:'GET',
            dataType:'json',
            data: {term: request.term},
            headers: {key:localStorage.getItem('key')},
            success: function(responseData) {
              
              var array = responseData.map(function(element){
                return {value: element['nationality'] , nationality: element['nationality'], country: element['country'], id: element['id']};
              });
              response(array);
  
            }
          })
        }, 
        select: function(event, ui) {
            moi.val(ui.item.nationality);
            context.dataService.edPatient.nationality_nabidh = ui.item.nationality;
            return false;
        }
    });
  });



  //Timepicker for new appointment
  $('body').on('focus', '.time-picker', function(){
    let moi = $(this);
    
    //@ts-ignore
    moi.autocomplete({
      appendTo: '#addDashAppointment',
      source: function(request, response) {
         //let array = [];
         //let rArr = request.term.split(":");
         //let hours = ["01"];
         let array = context.suggestTime(request.term);

         response(array);
        }, 
        select: function(event, ui) {
            moi.val(ui.item.value);
            return false;
        }
    });
  });

  }

  ngOnDestroy() {
    if (this.runNotifs) {
      clearInterval(this.runNotifs);
    }
  }


  clickEvent(){
    this.sidebar_toggle = !this.sidebar_toggle;       
}

  connectSocket(){
    this.socketService.setupSocketConnection();
  }

  loadNotifications() {
    this.dataService.getNotibar().subscribe( (data: any[] ) =>{
        this.notifications = data;
        
    });
  }

  loadSubscriptionStatus() {
    this.dataService.getSubscriptionStatus().subscribe( (data: any ) =>{
        this.subscriptionStatus = data;
    });
  }

  loadMenu() {
    this.dataService.getMenu().subscribe( (data: any[] ) =>{
        this.menu = data;
        
    });
  }

  loadEnquiryCategories() {
    this.dataService.getEnquiryCategories().subscribe( (data: any[] ) =>{
        this.ecats = data;
        
    });
  }

  loadOrg(){
    
    this.dataService.getOrg(this.selectedBranch.id).subscribe( (data: any = {org_id:0, ac:0} ) =>{
        
        this.orgId = data.org_id;
        this.dataService.localData.orgId = data.org_id;
        
        this.orgInfo = data;
        
        this.setBranch(data.ac);
        let aeOrgs = [3,4,6,7,8, 41];
        if(aeOrgs.includes(this.orgId) ){
          this.ids = this.idAe;
        } else {
          this.ids = this.idLocal;
        }
        
    });
  }

  clearNotifications(){
    this.dataService.postClearNotifications().subscribe( (res:any) => {
        if(res.status == 0) {
          this.loadNotifications();
        }

    } );
  }

  createBillItem(): FormGroup {
      return this.formBuilder.group({
        item: '',
        rate: '',
        qty: '',
        amount:0,
      })
    }

  addBillItem():void {
    this.billItems = this.billForm.get('billItems') as FormArray;
    this.billItems.push(this.createBillItem());
  }

  removeBillItem(index) {
    this.billItems.removeAt(index);

  }


  submitRegistration(data) {
    data.dob = $('#inputDOB').val();
    data.name = $('#inputName').val();
    data.oldregno = $('#inputReg').val();
    data.phone1 = $('#inputPhone').val();
    data.eid = $('#inputEid').val();
    data.patient_since = $('#inputSince').val();
    data.branch = this.selectedBranch.id;
    data.regExpand = this.regExpand;
    data.nationality_nabidh = this.nationality_nabidh;

    $('#btn-reg1').attr('disabled', 'true');
    $('#btn-reg1').text('Submitting');

    /*var treatments = [];

    $('ul.treatment-selector li').each(function(){
      if( $(this).hasClass('active') ) {
        treatments.push( $(this).text() );
      }
    })*/

    data.treatments = this.patientTreatments;

    data.languages = this.patientLanguages;

    console.log((data));
    
    
    
    this.dataService.postRegistration(data).subscribe( (res:any) => {

      $('#btn-reg1').removeAttr('disabled');
      $('#btn-reg1').text('Submit');
      
      if(res.status == 0) {
        alert(res.msg);
        $('#addPatient').modal('hide');
        $(".btn-cancel-reg").click();
        //Upload file if any
        if(this.upFiles.length){
          const formData = new FormData();
          for(let i=0 ; i < this.upFiles.length ; i++){
            formData.append('file[]', this.upFiles[i],this.upFiles[i].name);
          }
          formData.append('file_title', this.uploadForm.get('file_title').value);
          formData.append('patient_id', res.patient_id.toString());
          this.dataService.postFile(formData).subscribe( (res:any) => {
            if(res.status == 0) {
              //this.ngxService.stop('loader-01');
              
            }
           
          });
        }

        if(res.show_profile){
          this.router.navigate(['/dashboard/client/'+res.patient_id]);
        } else {
          this.router.navigate(['/dashboard/patients/branch']);
        }

        

        
      } else {
        alert(res.msg);
      }

    });

    
    
    

    
  }

  submitEditPatient(data){
    data.patient_id = this.dataService.edPatient.patient_id;
    data.nationality_nabidh = this.dataService.edPatient.nationality_nabidh;
    data.gender = $('input[name="edgender"]:checked').val();
    data.employment_status = $('input[name="edemployment_status"]:checked').val();
    //data.docinstructions = $('input[name="docinstructions"]:checked').val();
    data.patient_since = $('#editSince').val();
    data.dob = $('#editDOB').val();
    data.incomplete = 0;

    var treatments = [];

    $('ul.edit-treatments li').each(function(){
      if( $(this).hasClass('active') ) {
        treatments.push( $(this).text() );
      }
    })

    data.treatments = treatments;

    $('#btn-edit').text('Submitting');
    $('#btn-edit').attr('disabled', 'true');
    
    data.languages = this.dataService.edPatient.languages;
    
    this.dataService.postEditPatient(data).subscribe( (res:any) => {
      $('#btn-edit').text('Submit');
      $('#btn-edit').removeAttr('disabled');
      if(res.status == 0){
        $('#editPatient').modal('hide');
        $(".btn-cancel-modal").trigger("click");
        this.router.navigate(['/dashboard/client/'+this.dataService.edPatient.patient_id]);
      } else {
        alert(res.msg);
      }

    });
    
  }


  submitEnquiry(data) {
    console.log(data);
    $('#btn-submit-enquiry').attr('disabled', 'true');
    $('#btn-submit-enquiry').text('Submitting');

    

    data.enq_types = this.enqTags;
    data.branch = this.selectedBranch.id;

    this.dataService.postEnquiry(data).subscribe( (res:any) => {
      if(res.status == 0) {
          alert(res.msg);
          $('#enquiryModal').modal('hide');
          $(".btn-close-modal").trigger("click");
          $('#btn-submit-enquiry').removeAttr('disabled');
          $('#btn-submit-enquiry').text('Submit');
          
          this.router.navigate(['/dashboard/enquiry']);      
          
        } else {
          alert(res.msg);
        }
    } );
  }

  submitEvent(data) {
    data.date = $('#event-date').val();
    data.branch = this.selectedBranch.id;
    $('#btn-submit-event').attr('disabled', 'true');
    $('#btn-submit-event').text('Submitting');




    this.dataService.postEvent(data).subscribe( (res:any) => {
      if(res.status == 0) {
          alert(res.msg);
          $('#eventModal').modal('hide');
          $(".btn-close-modal").trigger("click");
          $('#btn-submit-event').removeAttr('disabled');
          $('#btn-submit-event').text('Submit');
          this.router.navigate(['/dashboard/events']);      
          
          
        } else {
          alert(res.msg);
        }
    } );
    

    

    
  }

  /*billing */

  submitBill(){
      $('#btn-submit').attr('disabled', 'true');
      $('#btn-submit').text('Submitting');

      var data = {"patient_id": $('.patient-details').attr('data-patient') , "billcontents":[]};
      var bc = [];

      $('.ab-row').each(function(){
        
        if ($(this).find('.ab-item').val() !== "" &&  $(this).find('.ab-rate').val() !== "" ) {
            
            var row = {
              "description":$(this).find('.ab-item').val(),
              "rate": $(this).find('.ab-rate').val(),
              "qty": $(this).find('.ab-qty').val(),
              "stock_id": $(this).find('.ab-item').attr('data-stock'),
              
            }

            bc.push(row);

        }

        
      });

      
      if (bc.length > 0) {
        
        data.billcontents = bc;
          this.dataService.postBill(data).subscribe( (res:any) => {
            $('#btn-submit').removeAttr('disabled');
            $('#btn-submit').text('Add');

            if(res.status == 0) {
              alert(res.msg);
              $('#modalBilling').modal('hide');
              $(".btn-close-modal").trigger("click");
              this.router.navigate(['/dashboard/billing']);
              

              
            } else {
              alert(res.msg);
            }

          });
        
      } else {
          alert('Bill cannot be empty');
          $('#btn-submit').removeAttr('disabled');
          $('#btn-submit').text('Add');
      }

      
      
      


  }

  showPatientSelect() {
      $('.patient-details').hide();
    $('.patient-details').attr('data-patient', 0);
    $('.input-select-patient').val('');
    $('.input-select-patient').show();
   }

    newPatient() {
      
      this.dataService.getNextPatientId().subscribe( (data: any[] ) =>{
          //@ts-ignore
          if (data.status == 0) {
            //@ts-ignore
            this.nextReg = data.reg_no;
          }
          
      });
      
    }

    selectRegNo(){
      $('#inputReg').val(this.nextReg);
    }

    markRead(id) {
      var data = {"id":id};

      this.dataService.postMarkRead(data).subscribe( (res:any) => {
        
        if(res.status == 0) {
             this.loadNotifications();
            
          }
      } );

    }



  signOut(){

    this.dataService.localData.orgId = 0;
    this.dataService.localData.userId = "";
    this.dataService.localData.roleId = "";
    this.dataService.localData.billContents = [];
    this.dataService.localData.dateDashboard = "";
    this.dataService.localData.dateDashboardReception = "";

    localStorage.removeItem('key');
    localStorage.removeItem('role_id');
    localStorage.removeItem('branch');
    localStorage.removeItem('user_id');
    localStorage.removeItem('department');
    localStorage.removeItem('allow_edit');
    this.router.navigate(['/login']);
  }

  submitIssue(data){

    $('#btn-report-issue').attr('disabled', 'true');
    $('#btn-report-issue').text('Sending...');

    
    data.page = window.location.href;
    data.screenshot = this.screenshotData;
    
    this.dataService.postReportIssue(data).subscribe( (res:any) => {
      //this.dataService.postCreateTicket(data).subscribe( (res:any) => {
      $('#btn-report-issue').removeAttr('disabled');  
      $('#btn-report-issue').text('Send');
      alert(res.msg);
      if (res.status == 0) {
        $('#modal-report-issue').modal('hide');
        $(".btn-close-modal").trigger("click");
      }

    });

  }

  expandReg(){

    if (this.regExpand == 0) {
      this.regExpand = 1;
    } else {
      this.regExpand = 0;
    }

  }

  setBranch(branch){
    
    localStorage.setItem("branch", JSON.stringify(branch));
    
  }

  switchBranch(branch){
    
    localStorage.setItem("branch", JSON.stringify(branch));
    this.selectedBranch = branch;
    
    this.router.navigate([this.router.url]);
  }

  calculateAge(val){
    var dob = new Date(val);
    var month_diff = Date.now() - dob.getTime();
    var age_dt = new Date(month_diff);   
    var year = age_dt.getUTCFullYear();  
    var age = Math.abs(year - 1970);  
    
    if(!isNaN(age) && age < 100 ){
      this.regAge = age;
    } else {
      this.regAge = "";
    }

  }

  languageInput(val, mode)  {
    if (val.includes(',')) {
      val = val.replace(',', '');
      if(mode == 'new'){
        this.patientLanguages.push(val);
      } else if(mode == 'edit'){
        this.dataService.edPatient.languages.push(val);
      }
      
      this.newLang = "";

    }
  }

  addHomeLang(){
    this.dataService.edPatient.languages.push(this.newLang);
    this.newLang = "";
    
  }

  addHomeLangNewPatient(){
    
    this.patientLanguages.push(this.newLang);
    this.newLang = "";
    
  }

  removeHomeLangNewPatient(index){
    this.patientLanguages.splice(index, 1);
  }

  removeHomeLang(index){
    this.dataService.edPatient.languages.splice(index, 1);
  }

  addTreatment(t){
    this.patientTreatments.push(t);
  }

  removeTreatment(index){
    this.patientTreatments.splice(index, 1);
  }

  addEnqTag(tag){
    if(!this.enqTags.includes(tag) && tag !=0 ){
      this.enqTags.push(tag);
    }
  }

  setPatientRefby(val){
    this.patientRefby = val;
    console.log(this.patientRefby);
  }


  ageCalculator(val) {    
    
    var dob = new Date(val);  
       
    if(val==null || val==''){  
      this.regAge = "";
      
    }   
        
    else {   
      var mdate = val.toString();  
      var dobYear = parseInt(mdate.substring(0,4), 10);  
      var dobMonth = parseInt(mdate.substring(5,7), 10);  
      var dobDate = parseInt(mdate.substring(8,10), 10);  
        
      var today = new Date();   
      var birthday = new Date(dobYear, dobMonth-1, dobDate);  
        
      var diffInMillisecond = today.valueOf() - birthday.valueOf();        
      var year_age = Math.floor(diffInMillisecond / 31536000000);  
      var day_age = Math.floor((diffInMillisecond % 31536000000) / 86400000);  
        
      
            
      var month_age = Math.floor(day_age/30);          
      day_age = day_age % 30;  
            
      var tMnt= (month_age + (year_age*12));  
      var tDays =(tMnt*30) + day_age;  
      if (dob>today) {  
          this.regAge = "";
          
        }  
        else {  
          this.regAge =  year_age + " years " + month_age + " months ";
          
        }  
   }  
  }  

  onFileSelect(event) {

    
    if(event.target.files.length > 0) {
      
      
      this.uploadForm.get('doc').setValue(event.target.files);
      
      for(let i=0 ; i < event.target.files.length ;i++){ 
        this.upFiles.push(<File>event.target.files[i]);
      }

    }
    

  }

  //New appointment - dashboard;
  submitAppointment(inputs) {
		var data = {
	      "consultant_id": 0,
	      "patient_id": this.newAppPatient,
	      "visit_purpose": 2,
	      "date":this.dataService.localData.homeDate,
	      "repeat_type": this.repeatType,
	      "repeat_count": this.repeatCount,
        "start_time": inputs.start_time,
        "duration": inputs.duration,
        "min_hr":inputs.min_hr
	    };

      data.start_time = $("#inputStartTime").val();

      console.log("fd", data);

	    if(data.patient_id == "" || data.patient_id == 0 ) {
	      alert('Select patient from suggestions');
	    } else {

	    	
	      $('#btn-appointment').attr('disabled', 'true');
	      $('#btn-appointment').text('Submitting');
	      
	      this.dataService.postAppointmentFlex(data).subscribe( (res:any) => {
	      	$('#btn-appointment').removeAttr('disabled');
	      	$('#btn-appointment').text('Add Appointment');
	      	this.repeatType = 0;
	        if(res.status == 0) {
	          alert(res.msg);
	          $('#addAppointment').modal('hide');
            $(".btn-close-modal").trigger("click");
            $(".btn-appointment-cancel").click();
	          //@ts-ignore
	          document.getElementById("formApp").reset(); 
	          
	          //this.loadDate(this.date);
	          this.router.navigate(['/dashboard/home/']);
	          
	        } else {
	          alert(res.msg);
	        }
	      }); 
	     
	    }

	    


	}

  setRepeatType(val) {
		this.repeatType = val;
		
	}

	setRepeatCount(val){
		this.repeatCount = val;

	}

  /*timepicker */

  suggestTime(term){
    let termArray = term.split(":");
    let hrTerm = termArray[0];
    let minTerm = null;
    
    let mins = ["00", "15", "30", "45"];
    
    if(termArray[1] !== undefined ){
      minTerm = termArray[1];
      
      if(minTerm.length <= 2 && isNaN(minTerm) == false) {
        if(parseInt(minTerm) < 6)  {
          mins = [minTerm + "0", minTerm + "5"];
          
        } else if (parseInt(minTerm) > 9 && parseInt(minTerm) < 60 ) {
          mins = [minTerm];
        }
      }

    } 

    let response = [];
    let hours = [];

    

    if(hrTerm.length <= 2 && isNaN(hrTerm) == false ) {
      let hrs = parseInt(hrTerm);
      if(hrs == 1) {
        hours.push("01");
        hours.push("11");
        hours.push("12");
      } else if(hrs <= 12) {
        hours.push(hrs);
      }
    } else {
      
      
    }

    hours.forEach(h => {
      mins.forEach(m => {
        if(h >= 6){
          response.push(h + ":" + m + " AM");
        }
        if(h <= 10){
          response.push(h + ":" + m + " PM");
        }
        
      })
    });

    return response.slice(0,12);

  }

  dismissSubscriptionPop(){
    this.dataService.localData.showSubscriptionPop = false;
  }

  shouldShowPaymentAlert(): boolean {
    /*if (this.dataService.localData.lastPaymentAlertAt === null) {
      return true;
    }

    const oneHour = 60 * 60 * 1000; // milliseconds in one hour
    //const oneHour = 10 * 1000; // milliseconds in one hour
    const now = new Date().getTime();
    const lastAlert = new Date(this.dataService.localData.lastPaymentAlertAt).getTime();
    return this.data.show_payment_alert === 1 && (now - lastAlert > oneHour);*/

    return false;
  }

  dismissPaymentAlert() {
    this.dataService.localData.lastPaymentAlertAt = new Date();
    console.log(this.dataService.localData.lastPaymentAlertAt);
  }

  getCookie() {
    const name = "invoiceToken";
    const cookie = document.cookie.split('; ').find(row => row.startsWith(name + '='));
    const cookieVal =  cookie ? cookie.split('=')[1] : null;
    console.log("cookie", cookieVal);
    
  }


}
