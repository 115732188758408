import { Component, OnInit, ViewChild, ChangeDetectorRef, NgZone } from '@angular/core';
import { DataService } from '../../data.service';
import { Router } from '@angular/router';
import { MatCalendar } from '@angular/material';
import { NgForm } from '@angular/forms';



@Component({
  selector: 'app-schedule-flex',
  templateUrl: './schedule-flex.component.html',
  styleUrls: ['./schedule-flex.component.scss']
})
export class ScheduleFlexComponent implements OnInit {

  @ViewChild(MatCalendar,{}) calendar: MatCalendar<Date>;
  @ViewChild('fapp') fapp: NgForm;

  constructor(private router:Router, private dataService: DataService, private changeDetectorRef: ChangeDetectorRef, private ngZone: NgZone) { }
  ptype:any='';
  data = [];
	customDate:any = 0;
	date:any = null;
  dept:any = 0;
  deptName = "";
  cancelView:any = 0;
  viewDone:any = 1;
  branch:any = {id:0, name:""};
  appointments:any = [];
  departments:any = {departments:[{id:0}]};
  selectedTherapist:any = 0;
  newAppPatient:any = 0;
  repeatType = 0;
	repeatCount = 2;
  defaultDuration:any = 45;
  min_hr:any = "minutes";
  selectedVisit = { patient_id:0, appointment_id:0, "upcoming":[], "upcoming_months":[], "past":[], "past_months":[]};
  
  selectedMonth = "";
	cancelSlotId = "";
	cancelFrom = '0';
	openSlot = {"slot_id":"", "therapist_id":""}; //slot that's currently open for viewing.
	other_appointments = [];
	other_months = [];
	appointmentsMode = 1;
  cancelMode = 0;
	cancelUpcoming = 0;
  selectedMonthd:Date;

  rescheduleId:any = 0;
  reschedule=[];
	therapists:any ={therapists:[]};
  rescheduleTherapist:any = 0;
  rescheduleDate= "";
  rescheduleDuration:any = 15;
  rescheduleTime:any = "";

  regDate:any; 
  nextReg="";
  selectedSessionDate:any = "";
  selectedSessionDept:any = {id:0, name:""};
  selectedSessionTherapist:any = {id:0, name:""};
  selectedSessionTime:any = "";
  selectedSessionDuration:any = 15;
  visitDurationPresets:any = [15, 20, 30, 45, 60];
  newSessions:any = [];
  htBookings:any = [];
  patientBills:any = [];
  selectedPatientPackage:any = {is_active:0, patient_id:0, package_id:0}
  selectedGroupSession:any = {participants:[], start_time:"", end_time:""}
  newGSPatientName:any = "";
  newAppMode:any = 1;
  groupSessionsAllowed:any = false;
  

  ngOnInit() {

    if(localStorage.getItem("branch") !== null){
      this.branch = JSON.parse( localStorage.getItem("branch"));
      
    }

    this.date = this.dataService.localData.dateSchedule;
		this.customDate = this.dataService.localData.dateSchedule;
    this.dept = this.dataService.localData.deptSchedule;
    this.deptName = this.dataService.localData.deptScheduleName;


    this.loadDates();
    this.loadDepartments();
    //this.getTherapists();
    this.loadHtBookings();

    let context = this;

    //date-picker

    $( function() {
			(<any>$("#reschedule-date" )).datepicker({
		    	dateFormat: "dd-mm-yy",
		    	beforeShow: function() {
			        setTimeout(function(){
			            $('.ui-datepicker').css('z-index', 99999999999999);
			        }, 0);
			    },
			    onSelect: function (dateText, inst) {
					this.focus();
			    }
			});
		});

		$( function() {
			(<any>$("#schedule-date" )).datepicker({
		    	dateFormat: "dd-mm-yy",
		    	onSelect: function (dateText, inst) {
					this.focus();

			    }
			});

			
		});

    
   /* $( function() {
      console.log("timepicker");
			(<any>$(".time-picker" )).timepicker({
        timeFormat: 'h:mm p',
        interval: 60,
        minTime: '10',
        maxTime: '6:00pm',
        defaultTime: '11',
        startTime: '10:00',
        dynamic: false,
        dropdown: true,
        scrollbar: true
			});

			
		}); */
    
    
  



    //patient autocomplete

    /*$('body').on('focus', '#inputNameApp', function(){
      let moi = $(this);
      
      
      //@ts-ignore
      moi.autocomplete({
        appendTo: '#addAppointment',
        source: function(request, response) {
            $.ajax({
              url:context.dataService.REST_API_SERVER + 'patient/autocomplete',
              type:'GET',
              dataType:'json',
              data: {term: request.term, branch:context.branch.id},
              headers: {key:localStorage.getItem('key')},
              success: function(responseData) {
                
                var array = responseData.map(function(element){
                  return {value: element['value'] , id: element['patient_id'], name: element['patient_name']};
                });
                response(array);
    
              }
            })
          }, 
          select: function(event, ui) {
              moi.val(ui.item.name);
              context.newAppPatient = ui.item.id;
              context.fetchTherapyPackage(ui.item);
              $("#inputNameApp").blur();
              return false;
          }
      });
    }); */

    $('body').on('focus', '#inputNameApp', function() {
      
      let moi = $(this);
  
      
      context.ngZone.run(() => {
        //@ts-ignore
        moi.autocomplete({
          appendTo: '#addAppointment',
          source: function(request, response) {
            $.ajax({
              url: context.dataService.REST_API_SERVER + 'patient/autocomplete',
              type: 'GET',
              dataType: 'json',
              data: { term: request.term, branch: context.branch.id },
              headers: { key: localStorage.getItem('key') },
              success: function(responseData) {
                var array = responseData.map(function(element) {
                  return { value: element['value'], id: element['patient_id'], name: element['patient_name'] };
                });
                response(array);
              }
            });
          },
          select: function(event, ui) {
            moi.val(ui.item.name);
            context.newAppPatient = ui.item.id;
            context.fetchTherapyPackage(ui.item);
            
            return false;
          }
        });
      });
    });

    $('body').on('focus', '#inputNameGS', function() {
      
      let moi = $(this);
  
      
      context.ngZone.run(() => {
        //@ts-ignore
        moi.autocomplete({
          appendTo: '#modal-group-session',
          source: function(request, response) {
            $.ajax({
              url: context.dataService.REST_API_SERVER + 'patient/autocomplete',
              type: 'GET',
              dataType: 'json',
              data: { term: request.term, branch: context.branch.id },
              headers: { key: localStorage.getItem('key') },
              success: function(responseData) {
                var array = responseData.map(function(element) {
                  return { value: element['value'], id: element['patient_id'], name: element['patient_name'] };
                });
                response(array);
              }
            });
          },
          select: function(event, ui) {
            moi.val(ui.item.name);
            
            let data = {
              visit_id: context.selectedGroupSession.appointment_id,
              patient_id: ui.item.id
            }
            context.dataService.postAddGSPatient(data).subscribe( (res:any) => {
              if(res.status == 0) {
                context.selectedGroupSession.participants.push(res.patient);
                context.newGSPatientName = "";
              } else {
                  alert(res.msg);
              }
            });
            
            return false;
          }
        });
      });
    });

    $('body').on('focus', '.time-picker', function(){
      let moi = $(this);
      
      //@ts-ignore
      moi.autocomplete({
        appendTo: '#addAppointment',
        source: function(request, response) {
           //let array = [];
           //let rArr = request.term.split(":");
           //let hours = ["01"];
           let array = context.suggestTime(request.term);
            console.log(request.term);
           response(array);
          }, 
          select: function(event, ui) {
              moi.val(ui.item.value);
              return false;
          }
      });
    });

    $('body').on('focus', '.time-picker-reschedule', function(){
      let moi = $(this);
      
      //@ts-ignore
      moi.autocomplete({
        appendTo: '#rescheduleModal',
        source: function(request, response) {
           //let array = [];
           //let rArr = request.term.split(":");
           //let hours = ["01"];
           let array = context.suggestTime(request.term);
          
           response(array);
          }, 
          select: function(event, ui) {
              moi.val(ui.item.value);
              context.rescheduleTime = ui.item.value;
              return false;
          }
      });
    });

    $('body').on('focus', '.time-picker-new', function(){
      let moi = $(this);
      
      //@ts-ignore
      moi.autocomplete({
        appendTo: '#detailsModal',
        source: function(request, response) {
           //let array = [];
           //let rArr = request.term.split(":");
           //let hours = ["01"];
           let array = context.suggestTime(request.term);
          
           response(array);
          }, 
          select: function(event, ui) {
              moi.val(ui.item.value);
              context.selectedSessionTime = ui.item.value;
              return false;
          }
      });
    });

  }

  isShowDiv = true;  
     
  toggleDisplayDiv() {  
    this.isShowDiv = !this.isShowDiv;
    //this.selectedDate = "" ;
  }  

  loadDates() {
		
		this.dataService.getDate(this.date, this.dept, this.customDate, this.cancelView, this.branch.id).subscribe( (data: any["selected"] )=> {
			this.data = data;
			this.date = data.selected;
      this.dataService.localData.dateSchedule = this.date;
      this.viewDone = data.view_completed_visits;
      this.loadAppointments();
      this.loadHtBookings();
		} );
		
	}

  loadDate(date){

    if(date !== null){
      this.customDate = date;
      this.date = date;
      this.dataService.localData.dateSchedule = this.date;
      this.loadDates();
    }

    
  }

  loadAppointments(){
    this.dataService.getAppointmentsFlex(this.date, this.dept, this.cancelView, this.branch.id, this.viewDone).subscribe( (data: any["data"] )=> {
			this.appointments = data.data;
			this.groupSessionsAllowed = data.allow_group_sessions;
		} );
  }

  loadDepartments() {
		this.dataService.getDepts().subscribe( (data: any["selected"] )=> {
			this.departments = data;
			this.selectedSessionDept = data.departments[0];
      if(this.dept==0){
        this.dept = data.departments[0].id;
      } 

      if(this.deptName == ""){
        this.deptName = data.departments[0].name;
      }

      
      this.getTherapists();
		} );
	}

  loadHtBookings(){
    this.dataService.getHtV2Bookings(this.date).subscribe( (data: any )=> {
			this.htBookings = data.bookings;
		} );
  }

  changeDept(dept, dept_id) {
		$('#dropdownMenuButton').text(dept);
		$('#dropdownMenuButton').attr('data-dept', dept_id);
		this.dept = dept_id;
    this.dataService.localData.deptSchedule = dept_id;
    this.dataService.localData.deptScheduleName = dept;
		this.loadDates();

		
		
	}

  setCancelView(){
    
    if(this.cancelView == 1){
      this.cancelView = 0;
    } else {
      this.cancelView = 1;
    }
		this.loadAppointments();
	}

  setDoneView(){
    
    if(this.viewDone == 1){
      this.viewDone = 0;
    } else {
      this.viewDone = 1;
    }

    let data = {viewDone:this.viewDone}
    this.dataService.postViewDoneStatus(data).subscribe( (res:any) => {
      console.log(res)  
    });

		this.loadAppointments();
	}

  openAppointment(consultant, selected){
		this.selectedTherapist = consultant;	  	
		
		//$('#inputConsultant').val(consultant);
		//$('#inputDate').val(selected);
		
		
		
	}

  submitAppointment(inputs) {
    var data;
    if(this.newAppMode == 1){
      data = {
	      "consultant_id": this.selectedTherapist,
	      "patient_id": this.newAppPatient,
	      "visit_purpose": 2,
	      "date":this.date,
	      "repeat_type": this.repeatType,
	      "repeat_count": this.repeatCount,
        "start_time": inputs.start_time,
        "duration": inputs.duration,
        "min_hr":inputs.min_hr,
        "package_body_id": inputs.package_body_id,
        "is_gt":false,
	    };
    } else {

      data = {
	      "consultant_id": this.selectedTherapist,
	      "patient_id": 0,
	      "visit_purpose": 2,
	      "date":this.date,
	      "repeat_type": this.repeatType,
	      "repeat_count": this.repeatCount,
        "start_time": inputs.start_time,
        "duration": inputs.duration,
        "min_hr":inputs.min_hr,
        "package_body_id": 0,
        "is_gt":true
	    };
    }

		

    data.start_time = $("#inputStartTime").val();

    let readyToGo = true;
    if(this.newAppMode == 1){
      if(data.patient_id == "" || data.patient_id == 0 ) {
        readyToGo = false;
      }
    }


    if(!readyToGo ) {
      alert('Select patient from suggestions');
    } else {

      
      $('#btn-appointment').attr('disabled', 'true');
      $('#btn-appointment').text('Submitting');
      
      this.dataService.postAppointmentFlex(data).subscribe( (res:any) => {
        $('#btn-appointment').removeAttr('disabled');
        $('#btn-appointment').text('Add Appointment');
        this.repeatType = 0;
        if(res.status == 0) {
          alert(res.msg);
          $('#addAppointment').modal('hide');
          $(".btn-appointment-cancel").click();
          this.newAppPatient = 0;
          //@ts-ignore
          document.getElementById("formApp").reset(); 
          
          
          this.fapp.resetForm({
            duration: 45,
            min_hr:"minutes",
            patient_name:""
          })
          
          //this.loadDate(this.date);
          this.loadAppointments();
          
        } else {
          alert(res.msg);
        }
      });
      
    }

	    


	}

  setRepeatType(val) {
		this.repeatType = val;
		
	}

	setRepeatCount(val){
		this.repeatCount = val;

	}

  viewVisit(slot){
    
    if(!slot.group_session){
      this.patientBills = [];

      this.dataService.getViewVisit(slot.appointment_id).subscribe( (data:any = {upcoming:[], upcoming_months:[]} )=> {
          
          this.selectedVisit = data;
          this.other_appointments = data.upcoming;
          this.other_months = data.upcoming_months;
          this.selectedMonth = data.upcoming_months[0];
          this.selectedTherapist = data.therapist_id;
          this.rescheduleTherapist = data.therapist_id;
          this.appointmentsMode = 1;
          this.selectedSessionDate = data.today;
          this.selectedSessionTherapist = {id: data.therapist_id, name:data.therapist_name} ;
          this.loadPatientBillsInit();    
          
        } );

      $('#detailsModal').modal('show');
    } else {
      this.selectedGroupSession = slot;
      $('#modal-group-session').modal('show');
    }

    

	}

  switchAppointmentsMode(mode){
    this.appointmentsMode = mode;
    
    if (mode==1) {
      this.selectedMonth = this.selectedVisit.upcoming_months[0];
      this.other_appointments = this.selectedVisit.upcoming;
      this.other_months = this.selectedVisit.upcoming_months;
    }else if(mode==2){
      this.selectedMonth = this.selectedVisit.past_months[this.selectedVisit.past_months.length - 1];
      this.other_appointments = this.selectedVisit.past;
      this.other_months = this.selectedVisit.past_months;
    }
  }

  markPresent(id) {
    	

    	

    var data = {"appointment_id":id};
    this.dataService.postMarkPresent(data).subscribe( (res:any) => {
        if(res.status == 0) {
          //@ts-ignore
            this.viewVisit(id);
           //this.loadDate(this.date);     
           this.loadAppointments();
          } else {
            alert(res.msg);
          }
    });

    

  }

  markAbsent(id) {
    	

    	

    var data = {"id":id};
    this.dataService.postMarkAbsent(data).subscribe( (res:any) => {
        if(res.status == 0) {
          //@ts-ignore
            this.viewVisit(id);
           //this.loadDate(this.date);     
           this.loadAppointments();
          } else {
            alert(res.msg);
          }
    });

    

  }

  markDischarge(id,val) {
      
      var data = {"appointment_id":id, "value":val};
      if (val == 3) {
        var msg = "Are you sure you want to discharge this patient?"
      } else if(val == 4) {
        var msg = "Are you sure you want to mark this patient discontinued?"
      }
      var cnf = confirm(msg);

      if (cnf) {
        this.dataService.postMarkDischarge(data).subscribe( (res:any) => {
            if(res.status == 0) {
              //@ts-ignore
                this.viewVisit(id);
              //this.loadDate(this.date);     
              this.loadAppointments();
              } else {
                alert(res.msg);
              }
        });
      }

      

  }

  setCancelMode(val){
		this.cancelMode = val;
		
	}
	setGroupCancelMode(index, val) {
		//@ts-ignore
		this.groupSlots.group[index].cancelMode = val;
	}

	setCancelUpcoming(){
		if (this.cancelUpcoming == 0 ) {
			this.cancelUpcoming =1;
		} else {
			this.cancelUpcoming = 0;
		}


	}
	setCancellation(val, from) {
		this.cancelSlotId = val;
		this.cancelFrom = from;

	}

	confirmCancel(){

		
		var data = {
			//@ts-ignore
			"appointment_id": this.cancelSlotId,
			"cancellation_mode": this.cancelMode,
			"cancel_upcoming":this.cancelUpcoming,
		}

		

		
		$('.btn-cancel-confirm').attr('disabled', 'true');
		$('.btn-cancel-confirm').text('loading.');

		
		
		this.dataService.postCancelAppointment(data).subscribe( (res:any) => {

			if(res.status == 0) {
		        alert(res.msg);
		        //clear data
		        this.cancelMode = 0;
		        this.cancelUpcoming = 0;
		        
		        $('.btn-cancel-confirm').removeAttr('disabled');
				    $('.btn-cancel-confirm').text('Confirm Cancel');
				
				if(this.cancelFrom == '1') {
					//@ts-ignore
					this.viewVisit(this.cancelSlotId);	

				}else{
					
				}

				//this.loadDate(this.date);
				this.loadAppointments();

				$('#cancelModal').modal('hide');
        $(".btn-cancel-modal").click();
		        
	      	} else {
	        	alert(res.msg);
	      	}

		});

		
		
		
		
	}

  loadReschedule(appointment_id) {

		$('#rescheduleModal').modal('show');

		this.rescheduleId = appointment_id;
		this.dataService.loadReschedule(appointment_id).subscribe( (data: any[] )=> {
			this.reschedule = data;
			
		} );
	}

	getTherapists(){
    this.dataService.getTherapists(0,1, this.selectedSessionDept.id, 1).subscribe( (data: any )=> {
      this.therapists = data;
      this.selectedSessionTherapist = {id: data.therapists[0].id, name:data.therapists[0].name};
    } );	
  }

	setRescheduleDate(val) {
		this.rescheduleDate = val;
		
	}

	changeTherapist(val){
		this.rescheduleTherapist = val;
		
	}

  rescheduleSlot() {
		var data = {
			"date":this.rescheduleDate,
			"therapist":this.rescheduleTherapist,
			"appointment_id":this.rescheduleId,
      "start_time": this.rescheduleTime,
      "duration":this.rescheduleDuration,
		};

    console.log("data", data);

		if(data.date == "") {
      alert("Select a date");
    } else if(data.start_time == "") {
      alert("Select start time");
    } else if(data.therapist == 0) {
      alert("Select a therapist");
    } else {
      this.dataService.postRescheduleFlex(data).subscribe( (res:any) => {

        if(res.status == 0) {
              alert(res.msg);
              $('#rescheduleModal').modal('hide');
              $('#reschedule-date').val('');
              
              //this.loadDate(this.date);
              this.loadAppointments();
              this.viewVisit(this.rescheduleId);
              
            } else {
              alert(res.msg);
            }
  
      } );
    }

		

	
	}

  openRegistration(date){
		this.regDate = date;
			
		this.dataService.getNextPatientId().subscribe( (data: any[] ) =>{
	          //@ts-ignore
	          if (data.status == 0) {
	            //@ts-ignore
	            this.nextReg = data.reg_no;
	          }
	          
	      });

		
	}


  /*timepicker */

  suggestTime(term){
    let termArray = term.split(":");
    let hrTerm = termArray[0];
    let minTerm = null;
    
    let mins = ["00", "15", "30", "45"];
    
    if(termArray[1] !== undefined ){
      minTerm = termArray[1];
      
      if(minTerm.length <= 2 && isNaN(minTerm) == false) {
        if(parseInt(minTerm) < 6)  {
          mins = [minTerm + "0", minTerm + "5"];
          
        } else if (parseInt(minTerm) > 9 && parseInt(minTerm) < 60 ) {
          mins = [minTerm];
        }
      }

    } 

    let response = [];
    let hours = [];

    

    if(hrTerm.length <= 2 && isNaN(hrTerm) == false ) {
      let hrs = parseInt(hrTerm);
      if(hrs == 1) {
        hours.push("01");
        hours.push("11");
        hours.push("12");
      } else if(hrs <= 12) {
        hours.push(hrs);
      }
    } else {
      
      
    }

    hours.forEach(h => {
      mins.forEach(m => {
        if(h >= 6 && h < 12){
          response.push(h + ":" + m + " AM");
        }
        if(h <= 10 || h == 12){
          response.push(h + ":" + m + " PM");
        }
        
      })
    });

    return response.slice(0,12);

  }

  selectMonth(month){
    this.selectedMonth = month;
    //$('#collapseExample').collapse('show');
  }

  onCalendarSelect(date){
		var offset = date.getTimezoneOffset() * 60000;
		var isoDate = new Date(date.getTime() - offset ).toISOString().split('T')[0];
		this.selectedSessionDate = isoDate;
		
	}

  resetmodal(){
    // alert("hoo")
    this.selectedSessionDate = new Date().toISOString().split('T')[0];
    // this.selectedMonthd.setMonth(this.selectedMonthd.getMonth() + 1);
    // this.calendar._goToDateInView(this.selectedSessionDate,'month')
    this.calendar.activeDate= new Date();
    this.calendar.updateTodaysDate();
  }

  setSessionParam(param, val){
    this[param] = val;
    console.log(param, this[param]);
  }

  setSessionTherapist(id){
    let filtered = this.therapists.therapists.find( (e) => e.id == id );
    this.selectedSessionTherapist = filtered;
  }

  setSessionDept(id){
    let filtered = this.departments.departments.find( (e) => e.id == id );
    this.selectedSessionDept = filtered;
    
    this.getTherapists();
    
  }

  stageNewSession(){
    
    if(this.selectedSessionTime == ""){
      alert("Select appointment time");
    } else {

      

      let session = {
        date: this.selectedSessionDate,
        dept:this.selectedSessionDept,
        therapist: this.selectedSessionTherapist,
        time: this.selectedSessionTime,
        duration: this.selectedSessionDuration
      };

      //check if another visit for same date and time exists;
      let repeatFlag = 0;
      this.newSessions.forEach(element => {
        if(element.date == session.date && element.time == session.time){
          repeatFlag++;
        }
      });

      if(repeatFlag == 0){
        this.newSessions.push(session);
      } else {
        //alert("You already have another appointment ");
      }
  
      
    }

    
  }

  deleteStageSession(index){
    this.newSessions.splice(index,2);
    
  }

  confirmNewSessions(){
    let data = {patient_id:this.selectedVisit.patient_id, slots:this.newSessions}
    this.dataService.postNewSessions(data).subscribe( (res:any) => {
      if(res.status == 0) {
        alert("New appointments added.");
        //@ts-ignore
        this.viewVisit(this.selectedVisit.appointment_id);
        this.newSessions = [];
        this.toggleDisplayDiv();      
         
        } else {
          alert(res.msg);
        }
    });
  }

  closeSchedulePop(){
    $(".btn-cancel-appview").trigger("click");
  }

  loadPatientBillsInit(){
    this.patientBills = [];
    this.loadPatientBills();

  }

  loadPatientBills(){

    
    this.dataService.getPatientBills(this.selectedVisit.patient_id, this.ptype).subscribe( (data: any )=> {
			if(data.status == 0){
        this.patientBills = data.bills;
      }
			
		} );
  }

  filterPatientBills(ptype){
    this.ptype = ptype;
    this.loadPatientBills();
  }

  printPage(){
    window.print();
  }

  triggerRescheduleCalendar(){
    console.log("triggered");
    $("#reschedule-date").trigger("focus");
  }

  exportSchedule(){
    var key = localStorage.getItem("key");
    window.open(this.dataService.REST_API_SERVER + "reports/schedules&date="+this.date+"&key="+key);
  }

  fetchTherapyPackage(patient){
    this.dataService.getPatientPackage(patient.id).subscribe( (data: any ) =>{
      if(data.status == 0){
        this.selectedPatientPackage = data.package;
        console.log(this.selectedPatientPackage);
        this.changeDetectorRef.detectChanges(); 
      }
    });
  }

  toggleAvailability(doc, mode){
    
    let avText = (mode == 1) ? 'unavailable' : 'available';

    let cnfQuestion = "Do you really want to mark " + doc.name + " as " + avText + " on " + this.date;

    let cnf = confirm(cnfQuestion);

    if(cnf){
      let data = {
        id: doc.id,
        mode: mode,
        date:this.date
      }

      this.dataService.postMarkDocAvailability(data).subscribe( (res:any) => {
        if(res.status == 0) {
          doc.is_leave = mode;   
        } else {
            alert(res.msg);
        }
      });
    }

  }


  removeGSPatient(patient, index){
    

    let cnf = confirm("Do you really want to remove " + patient.name + " from this group session?");

    if(cnf){
      
      let data = {
        id: patient.assignment_id,
        
      };

      console.log(data);
      this.dataService.postRemoveGSPatient(data).subscribe( (res:any) => {
        if(res.status == 0) {
          this.selectedGroupSession.participants.splice(index,1);
        } else {
            alert(res.msg);
        }
      });
    }

  }


  setNewAppMode(mode){
    this.newAppMode = mode;
  }


  submitReport(data){
    console.log(data)

    this.dataService.getGenerateDisposableToken().subscribe( (res:any) => {
      if(res.status == 0) {
        
        window.open(this.dataService.REST_API_SERVER 
        + "reports/visits_report&date1="+data.date1+"&date2="+data.date2
        +"&attendance="+data.attendance
        +"&token="+res.token          
        
        );
    
      } else {
        alert(res.msg);
      }
      });

  }

  

}
