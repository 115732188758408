import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { DataService } from '../../data.service';
import { MatCalendar } from '@angular/material';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-sidebar-bottom',
  templateUrl: './sidebar-bottom.component.html',
  styleUrls: ['../../dashboard/patient-profile/patient-profile.component.scss', '../../dashboard/schedule-flex/schedule-flex.component.scss', './sidebar-bottom.component.scss']
})
export class SidebarBottomComponent implements OnInit {
	@ViewChild(MatCalendar,{}) calendar: MatCalendar<Date>;
	@ViewChild('fapp') fapp: NgForm;
  @Input('id') id:any;
  @Input('profileViewMode') profileViewMode:any;
  ptype:any='';
  appointments:any = {status:0, appointments:[]};
  appMode:any = 1;
  appLimit:any = 5;
  tools:any = [];
  F2Forms:any = [];
  referralStatus:any = {status:false}
  

  prescriptions = [
	  {
		  item:"",
		  generic_name:"",
		  regime:"",
		  duration:"",
		  remarks:"",
		  stock_id:0,
	  }
  ];

  //chart-demo

  colors = [
		'#F2B705',
		
		'#038C33',
		'#F20505',
		'#194A2F',
		'#8C1B39',
		'#FF6175',
		'#7AFF96',
		'#2A6637',

	];

  graphData:any = {
          "status":0,
          "graphdata":[25, 40, 50, 68],"dates":["1 Aug", "9 Aug", "10 Aug", "11 Aug"]
        };

  orgForms:any = [];
  branches = [];

  /*view appointments */
  selectedTherapist:any = 0;
  selectedVisit = { patient_id:0, appointment_id:0, "upcoming":[], "upcoming_months":[], "past":[], "past_months":[]};
  
  selectedMonth = "";
	cancelSlotId = "";
	cancelFrom = '0';
	openSlot = {"slot_id":"", "therapist_id":""}; //slot that's currently open for viewing.
	other_appointments = [];
	other_months = [];
	appointmentsMode = 1;
  cancelMode = 0;
	cancelUpcoming = 0;
  selectedMonthd:Date;

  rescheduleId:any = 0;
  reschedule=[];
	therapists:any ={therapists:[]};
  rescheduleTherapist:any = 0;
  rescheduleDate= "";
  rescheduleDuration:any = 15;
  rescheduleTime:any = "";

  regDate:any; 
  nextReg="";
  selectedSessionDate:any = "";
  selectedSessionDept:any = {id:0, name:""};
  selectedSessionTherapist:any = {id:0, name:""};
  selectedSessionTime:any = "";
  selectedSessionDuration:any = 15;
  visitDurationPresets:any = [15, 20, 30, 45, 60];
  newSessions:any = [];
  htBookings:any = [];
  patientBills:any = [];
  departments:any = {departments:[{id:0}]};

  constructor(private dataService: DataService, private router:Router, private route: ActivatedRoute) { }

  ngOnInit() {
	console.log("userId", this.dataService.localData.userId);
    this.loadAppointments();
    this.plotGraph();
	this.loadOrgForms();
	this.loadToolsForms();
	this.loadF2Forms();
	this.loadReferralStatus();
	this.loadBranches();
	this.loadDepartments();
	
	

	//set selectedTherapist if user is a consultant

	if(this.dataService.localData.roleId == '3' || this.dataService.localData.roleId == '4'){
		this.selectedSessionTherapist.id = this.dataService.localData.userId;
	}

	console.log("selectedTherapist", this.selectedSessionTherapist)

	//@ts-ignore
	if(this.dataService.localData.roleId == 3 || this.dataService.localData.roleId == 4){
		this.profileViewMode = 1;
	}

	/*auto-completes & datepickers*/

	let context = this;

	$( function() {
		(<any>$("#reschedule-date" )).datepicker({
			dateFormat: "dd-mm-yy",
			beforeShow: function() {
				setTimeout(function(){
					$('.ui-datepicker').css('z-index', 99999999999999);
				}, 0);
			},
			onSelect: function (dateText, inst) {
				this.focus();
			}
		});
	});

	$( function() {
		(<any>$("#schedule-date" )).datepicker({
			dateFormat: "dd-mm-yy",
			onSelect: function (dateText, inst) {
				this.focus();

			}
		});

		
	});

	$('body').on('focus', '.time-picker', function(){
		let moi = $(this);
		
		//@ts-ignore
		moi.autocomplete({
		  appendTo: '#addAppointment',
		  source: function(request, response) {
			 //let array = [];
			 //let rArr = request.term.split(":");
			 //let hours = ["01"];
			 let array = context.suggestTime(request.term);
			  console.log(request.term);
			 response(array);
			}, 
			select: function(event, ui) {
				moi.val(ui.item.value);
				return false;
			}
		});
	  });
  
	  $('body').on('focus', '.time-picker-reschedule', function(){
		let moi = $(this);
		
		//@ts-ignore
		moi.autocomplete({
		  appendTo: '#rescheduleModal',
		  source: function(request, response) {
			 //let array = [];
			 //let rArr = request.term.split(":");
			 //let hours = ["01"];
			 let array = context.suggestTime(request.term);
			
			 response(array);
			}, 
			select: function(event, ui) {
				moi.val(ui.item.value);
				context.rescheduleTime = ui.item.value;
				return false;
			}
		});
	  });
  
	  $('body').on('focus', '.time-picker-new', function(){
		let moi = $(this);
		
		//@ts-ignore
		moi.autocomplete({
		  appendTo: '#detailsModal',
		  source: function(request, response) {
			 //let array = [];
			 //let rArr = request.term.split(":");
			 //let hours = ["01"];
			 let array = context.suggestTime(request.term);
			
			 response(array);
			}, 
			select: function(event, ui) {
				moi.val(ui.item.value);
				context.selectedSessionTime = ui.item.value;
				return false;
			}
		});
	  });

	  /* stock autocomplete */

	  $('body').on('focus', '.stock-input', function(){
		let moi = $(this);
		let i = moi.parent().parent().attr("data-index");
		//@ts-ignore
		moi.autocomplete({
		  appendTo: '#prescriptionModal',
		  source: function(request, response) {
			  $.ajax({
				url:context.dataService.REST_API_SERVER + 'pharmacy/autocomplete',
				type:'GET',
				dataType:'json',
				data: {term: request.term},
				headers: {key:localStorage.getItem('key')},
				success: function(responseData) {
				  
				  var array = responseData.map(function(element){
					return {
					  value: element['drug'] + " (Balance: "+element['balance']+")", 
					  id: element['id'],
					  drug: element['drug'],
					  batch: element['batch'],
					  expiry: element['expiry'],
					  balance: element['balance'],
					  rate: element['rate'],
					  generic: element['generic'],
					};
				  });
				  response(array);
	  
				}
			  })
			}, 
			select: function(event, ui) {
				moi.val(ui.item.drug);
				context.selectItem(i, ui.item);
				moi.parent().parent().find(".generic-input").trigger("focus");
				return false;
			}
		});
	  });

  }

  loadBranches(){
	this.dataService.getBranches().subscribe( (data: any ) =>{
        this.branches = data;
        
        
    });
  }

  
  

  loadF2Forms(){
    this.dataService.getF2FormsPatient(this.id).subscribe( (data: any[] ) =>{
      this.F2Forms = data;
    });
  }

  loadAppointments(){
    this.dataService.getClientAppointments(this.id, this.appMode, this.appLimit).subscribe( (data: any[] )=> {
      this.appointments = data;
      
    });
  }  

  loadDepartments() {
	this.dataService.getDepts().subscribe( (data: any["selected"] )=> {
		this.departments = data;
		this.selectedSessionDept = data.departments[0];
		this.getTherapists();
	} );
  }

  loadOrgForms(){
	  this.dataService.getHtV2OrgForms().subscribe( (data: any ) => {
		  this.orgForms = data.forms;
		  
	  });
  }

  loadReferralStatus(){
	this.dataService.getClientReferralStatus(this.id).subscribe( (data: any ) => {
		this.referralStatus = data;
		
	});
  }

  switchAppMode(mode){
    if(mode != this.appMode){
      this.appMode = mode;
      this.loadAppointments();
    }
  }

  loadToolsForms(){
	console.log("patientId", this.id);
    this.dataService.getToolsForms(this.id).subscribe( (data: any[] ) =>{
      this.tools = data;
      
	  this.tools.forEach(element => {
		  element.route = element.route.replace("<id>", this.id);
	  });

    });
  }

  plotGraph(){
    //@ts-ignore
		$('#graph').highcharts({
          chart:{
            height:150
          },
	        title: {
	            text: ' ',
	            x: -20 //center
	        },
	        
	        xAxis: {
	            gridLineWidth: 0,
	            //categories: [0,30,60,90, 120, 150, 180]
	            categories: this.graphData.dates
	        },
	        yAxis: {
	            reversed: false,
	            showFirstLabel: false,
	            //categories: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
	            min:0, 
	            max:100,
	            tickInterval: 25,
	            title: {
	                text: '',
	                reversed: false,
	            },
	            plotLines: [{
	                value: 0,
	                width: 0,
	                color: '#FF0000'
	            }]
	        },
	        tooltip: {
	            
	        },
	        legend: {
	            layout: 'vertical',
	            align: 'right',
	            verticalAlign: 'middle',
	            borderWidth: 0
	        },
	        series: [{
	            name: ' ',
	            color: '#802a8f',
	            type: 'spline',
	            /*marker: {
	                //symbol: 'url(img/graph-marker.jpg)'
	            },*/
	            data:this.graphData.graphdata,
	        }]
	    });
		//
	}

	submitRefer(data){

		$('#btn-refer').attr('disabled', 'true');
		$('#btn-refer').text('Submitting');
	
		data.patient_id = this.id;
		
		
		
		this.dataService.postRefer(data).subscribe( (res:any) => {
		  $('#btn-refer').removeAttr('disabled');
			$('#btn-refer').text('Submit');
		  if(res.status == 0) {
			alert(res.msg);
			$('#modalRefer').modal('hide');
			$(".btn-close-modal").trigger("click");
			
		  } else {
			alert(res.msg);
		  }
		});
		
	}

	submitTest(data) {
		data.patient_id = this.id;
		$('#btn-atest').attr('disabled', 'true');
	
		this.dataService.postAudioTest(data).subscribe( (res:any) => {
		  
		  $('#btn-atest').removeAttr('disabled');
		  if(res.status == 0){
			$('#modalAudioTest').modal('hide');
			$(".btn-close-modal").trigger("click");
			//this.router.navigate(['/dashboard/patient/'+this.id+'/overview']);
		  } else {
			alert(res.msg);
		  }
	
		});
	
	}


	viewVisits(){
		this.dataService.getViewPatientVisits(this.id).subscribe( (data:any = {upcoming:[], upcoming_months:[]} )=> {
				
			this.selectedVisit = data;
			this.other_appointments = data.upcoming;
			this.other_months = data.upcoming_months;
			this.selectedMonth = data.upcoming_months[0];
			this.selectedTherapist = data.therapist_id;
			this.rescheduleTherapist = data.therapist_id;
			this.appointmentsMode = 1;
			this.selectedSessionDate = data.today;
			//this.selectedSessionTherapist = {id: data.therapist_id, name:data.therapist_name} ;
			this.loadPatientBillsInit();    
					
		} );

		$('#detailsModal').modal('show');
		
	}

	isShowDiv = true;  
     
	toggleDisplayDiv() {  
		this.isShowDiv = !this.isShowDiv;
		//this.selectedDate = "" ;
	}

	switchAppointmentsMode(mode){
		this.appointmentsMode = mode;
		
		if (mode==1) {
		  this.selectedMonth = this.selectedVisit.upcoming_months[0];
		  this.other_appointments = this.selectedVisit.upcoming;
		  this.other_months = this.selectedVisit.upcoming_months;
		}else if(mode==2){
		  this.selectedMonth = this.selectedVisit.past_months[this.selectedVisit.past_months.length - 1];
		  this.other_appointments = this.selectedVisit.past;
		  this.other_months = this.selectedVisit.past_months;
		}
	  }
	
	  markPresent(id) {
			
	
			
	
		var data = {"appointment_id":id};
		this.dataService.postMarkPresent(data).subscribe( (res:any) => {
			if(res.status == 0) {
			  //@ts-ignore
				this.viewVisits(id);
			   //this.loadDate(this.date);     
			   this.loadAppointments();
			  } else {
				alert(res.msg);
			  }
		});
	
		
	
	  }
	
	  markDischarge(id,val) {
		  
		  var data = {"appointment_id":id, "value":val};
		  if (val == 3) {
			var msg = "Are you sure you want to discharge this patient?"
		  } else if(val == 4) {
			var msg = "Are you sure you want to mark this patient discontinued?"
		  }
		  var cnf = confirm(msg);
	
		  if (cnf) {
			this.dataService.postMarkDischarge(data).subscribe( (res:any) => {
				if(res.status == 0) {
				  //@ts-ignore
					this.viewVisits();
				  //this.loadDate(this.date);     
				  this.loadAppointments();
				  } else {
					alert(res.msg);
				  }
			});
		  }
	
		  
	
	  }
	
	  setCancelMode(val){
			this.cancelMode = val;
			
		}
		setGroupCancelMode(index, val) {
			//@ts-ignore
			this.groupSlots.group[index].cancelMode = val;
		}
	
		setCancelUpcoming(){
			if (this.cancelUpcoming == 0 ) {
				this.cancelUpcoming =1;
			} else {
				this.cancelUpcoming = 0;
			}
	
	
		}
		setCancellation(val, from) {
			this.cancelSlotId = val;
			this.cancelFrom = from;
	
		}
	
		confirmCancel(){
	
			
			var data = {
				//@ts-ignore
				"appointment_id": this.cancelSlotId,
				"cancellation_mode": this.cancelMode,
				"cancel_upcoming":this.cancelUpcoming,
			}
	
			
	
			
			$('.btn-cancel-confirm').attr('disabled', 'true');
			$('.btn-cancel-confirm').text('loading.');
	
			
			
			this.dataService.postCancelAppointment(data).subscribe( (res:any) => {
	
				if(res.status == 0) {
					alert(res.msg);
					//clear data
					this.cancelMode = 0;
					this.cancelUpcoming = 0;
					
					$('.btn-cancel-confirm').removeAttr('disabled');
						$('.btn-cancel-confirm').text('Confirm Cancel');
					
					if(this.cancelFrom == '1') {
						//@ts-ignore
						this.viewVisits();	
	
					}else{
						
					}
	
					//this.loadDate(this.date);
					this.loadAppointments();
	
					$('#cancelModal').modal('hide');
			$(".btn-cancel-modal").click();
					
				  } else {
					alert(res.msg);
				  }
	
			});
	
			
			
			
			
		}
	
	  loadReschedule(appointment_id) {
	
			$('#rescheduleModal').modal('show');
	
			this.rescheduleId = appointment_id;
			this.dataService.loadReschedule(appointment_id).subscribe( (data: any[] )=> {
				this.reschedule = data;
				
			} );
		}
	
		getTherapists(){
			this.dataService.getTherapists(0,1, this.selectedSessionDept.id).subscribe( (data: any )=> {
				this.therapists = data;
				this.selectedSessionTherapist = {id: data.therapists[0].id, name:data.therapists[0].name};
			} );	
		}
	
		setRescheduleDate(val) {
			this.rescheduleDate = val;
			
		}
	
		changeTherapist(val){
			this.rescheduleTherapist = val;
			
		}
	
	  rescheduleSlot() {
			var data = {
				"date":this.rescheduleDate,
				"therapist":this.rescheduleTherapist,
				"appointment_id":this.rescheduleId,
				"start_time": this.rescheduleTime,
				"duration":this.rescheduleDuration,
			};
	
		console.log("data", data);
	
			if(data.date == "") {
		  alert("Select a date");
		} else if(data.start_time == "") {
		  alert("Select start time");
		} else if(data.therapist == 0) {
		  alert("Select a therapist");
		} else {
		  this.dataService.postRescheduleFlex(data).subscribe( (res:any) => {
	
			if(res.status == 0) {
				  alert(res.msg);
				  $('#rescheduleModal').modal('hide');
				  $('#reschedule-date').val('');
				  
				  //this.loadDate(this.date);
				  this.loadAppointments();
				  this.viewVisits();
				  
				} else {
				  alert(res.msg);
				}
	  
		  } );
		}
	
			
	
		
		}


		selectMonth(month){
			this.selectedMonth = month;
			//$('#collapseExample').collapse('show');
		  }
		
		  onCalendarSelect(date){
				var offset = date.getTimezoneOffset() * 60000;
				var isoDate = new Date(date.getTime() - offset ).toISOString().split('T')[0];
				this.selectedSessionDate = isoDate;
				
			}
		
		  resetmodal(){
			// alert("hoo")
			this.selectedSessionDate = new Date().toISOString().split('T')[0];
			// this.selectedMonthd.setMonth(this.selectedMonthd.getMonth() + 1);
			// this.calendar._goToDateInView(this.selectedSessionDate,'month')
			this.calendar.activeDate= new Date();
			this.calendar.updateTodaysDate();
		  }
		
		  setSessionParam(param, val){
			this[param] = val;
			console.log(param, this[param]);
		  }
		
		  setSessionTherapist(id){
			let filtered = this.therapists.therapists.find( (e) => e.id == id );
			this.selectedSessionTherapist = filtered;
		  }
		
		  setSessionDept(id){
			let filtered = this.departments.departments.find( (e) => e.id == id );
			this.selectedSessionDept = filtered;
			
			this.getTherapists();
			
		  }
		
		  stageNewSession(){
			
			if(this.selectedSessionTime == ""){
			  alert("Select appointment time");
			} else {
		
			  
		
			  let session = {
				date: this.selectedSessionDate,
				dept:this.selectedSessionDept,
				therapist: this.selectedSessionTherapist,
				time: this.selectedSessionTime,
				duration: this.selectedSessionDuration
			  };

			  //Check if another patient has a visit at the selected time;

			  this.dataService.getCheckVisitConflict(session.date, session.time, session.duration, session.therapist.id).subscribe( (data: any )=> {
				if(data.status){
					alert(data.msg)
				} else {
					//Proceed;

					//check if another visit for same date and time exists - in the same staging;
					let repeatFlag = 0;
					this.newSessions.forEach(element => {
					  if(element.date == session.date && element.time == session.time){
						repeatFlag++;
					  }
					});
			  
					if(repeatFlag == 0){
					  this.newSessions.push(session);
					} else {
					  //alert("You already have another appointment ");
					}

				}
			  } );
		
			  
		  
			  
			}
		
			
		  }
		
		  deleteStageSession(index){
			console.log(index);
			this.newSessions.splice(index,2);
			
		  }
		
		  confirmNewSessions(){
			let data = {patient_id:this.selectedVisit.patient_id, slots:this.newSessions}
			this.dataService.postNewSessions(data).subscribe( (res:any) => {
			  if(res.status == 0) {
				alert("New appointments added.");
				//@ts-ignore
				this.viewVisits();
				this.newSessions = [];
				this.toggleDisplayDiv();      
				this.loadAppointments();
				} else {
				  alert(res.msg);
				}
			});
		  }
		
		  closeSchedulePop(){
			$(".btn-cancel-appview").trigger("click");
		  }

	/*timepicker */

	suggestTime(term){
		console.log("hey");
		let termArray = term.split(":");
		let hrTerm = termArray[0];
		let minTerm = null;
		
		let mins = ["00", "15", "30", "45"];
		
		if(termArray[1] !== undefined ){
		  minTerm = termArray[1];
		  
		  if(minTerm.length <= 2 && isNaN(minTerm) == false) {
			if(parseInt(minTerm) < 6)  {
			  mins = [minTerm + "0", minTerm + "5"];
			  
			} else if (parseInt(minTerm) > 9 && parseInt(minTerm) < 60 ) {
			  mins = [minTerm];
			}
		  }
	
		} 
	
		let response = [];
		let hours = [];
	
		
	
		if(hrTerm.length <= 2 && isNaN(hrTerm) == false ) {
		  let hrs = parseInt(hrTerm);
		  if(hrs == 1) {
			hours.push("01");
			hours.push("11");
			hours.push("12");
		  } else if(hrs <= 12) {
			hours.push(hrs);
		  }
		} else {
		  
		  
		}
	
		hours.forEach(h => {
		  mins.forEach(m => {
			if(h >= 6 && h < 12){
			  response.push(h + ":" + m + " AM");
			}
			if(h <= 10 || h == 12){
			  response.push(h + ":" + m + " PM");
			}
			
		  })
		});
	
		return response.slice(0,12);
	
	  }

	  triggerRescheduleCalendar(){
		console.log("triggered");
		$("#reschedule-date").trigger("focus");
	  }

	  loadPatientBillsInit(){
		this.patientBills = [];
		this.loadPatientBills();
	
	  }
	
	  loadPatientBills(){
	
		
		this.dataService.getPatientBills(this.selectedVisit.patient_id, this.ptype).subscribe( (data: any )=> {
				if(data.status == 0){
			this.patientBills = data.bills;
		  }
				
			} );
	  }
	
	  filterPatientBills(ptype){
		this.ptype = ptype;
		this.loadPatientBills();
	  }

	  addPrescriptionRow(){
		this.prescriptions.push({
			item:"",
			generic_name:"",
			regime:"",
			duration:"",
			remarks:"",
			stock_id:0,
		});
	  }

	  removePrescriptionRow(index){
		this.prescriptions.splice(index, 1);
	  }
	
	  selectItem(i, item){
		this.prescriptions[i].item = item.drug;
		this.prescriptions[i].generic_name = item.generic;
		this.prescriptions[i].stock_id = item.id;
	  }


	  savePrescription(){
		  let data = {
			  patient_id: this.id,
			  body: this.prescriptions
		  };

		  $('#btn-prescribe').attr('disabled', 'true');
		  $('#btn-prescribe').text('Submitting');

		  this.dataService.postMedicinePrescription(data).subscribe( (res:any) => {
			$('#btn-prescribe').removeAttr('disabled');
			$('#btn-prescribe').text('Save prescription');
			if(res.status == 0) {
			  $('#prescriptionModal').modal('hide');
			  $(".btn-close-modal").trigger("click");
			  this.router.navigate(['/dashboard/pharmacy/prescription/'+res.id]);
			  
			} else {
			  alert(res.msg);
			}
		  });
	}


	
	  

}
