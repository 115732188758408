import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-client-wallet',
  templateUrl: './client-wallet.component.html',
  styleUrls: ['../patient-profile/patient-profile.component.scss', './client-wallet.component.scss']
})
export class ClientWalletComponent implements OnInit {

  constructor(private router:Router, private route: ActivatedRoute, private dataService: DataService) { }
  id:any = 0;
  data:any = {balance:0, transactions:[], count:0}
  limit:any = 30;
  offset:any = 0;
  logo:any = "";
  address = "";
  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id');
    this.loadData();
    this.loadLogo()
  }

  loadData(){
    this.dataService.getClientWallet(this.id, this.limit, this.offset).subscribe( (data: any[] )=> {
      this.data = data;
      
    });
  }

  loadLogo(){
    this.dataService.getLogoData().subscribe( (data: any = {status:0, logo_data:""} )=> {
      if(data.status == 0){
        this.logo = data.logo_data;
        this.address = data.address;
      }
      
      
    });
  }

  addMoney(data){
    
    data.patient_id = this.id;
    
    this.dataService.postClientWalletAdd(data).subscribe( (res:any) => {
      if(res.status == 0){
        this.loadData();
        $(".btn-close-modal").trigger("click");
      } else {
        alert(res.msg);
      }
    });
    

    

  }

  refundMoney(){
    let amount = prompt("Enter amount", this.data.balance);

    if(amount){
      let data = {
        patient_id:this.id,
        amount:amount
      }
  
      this.dataService.postClientWalletRefund(data).subscribe( (res:any) => {
        if(res.status == 0){
          this.loadData();
        } else {
          alert(res.msg);
        }
      });
    }
  }

  setCustomBalance(){
    let amount = prompt("Enter amount");

    if(amount){
      let data = {
        patient_id:this.id,
        amount:amount
      }
  
      this.dataService.postClientWalletCustomBalance(data).subscribe( (res:any) => {
        if(res.status == 0){
          this.loadData();
        } else {
          alert(res.msg);
        }
      });
    }
  }

  paginate(dir){
    if(dir == 2) {
      this.offset += this.limit;
    } else {
      this.offset -= this.limit;
      if(this.offset < 0){
        this.offset = 0;
      }
    }

    this.loadData();

  }


  printReceipt(receipt){
    //@ts-ignore
    var doc = new jsPDF();
    var y = 10;
    var yr = 30;
    
    //Logo
    if(this.logo != null){
      doc.addImage(this.logo, 'PNG', 30,0,155,37); // logo dimensions: 210x50mm
      y+=30; yr+=20;
    }
    
    
    doc.setFontSize(10);
    doc.setFontType('bold');
    doc.text(100, y, 'Receipt');
    y+=10;
  
    //from
    doc.setFontSize(8);
    doc.text(10,y, this.data.org.name);
    y+=5;
    doc.setFontType('normal');
    if(this.data.org.address != null){
  
      if(this.data.org.address.length < 40){
        doc.text(10,y, this.data.org.address);
        y+=5;
      } else {
        var splitText = doc.splitTextToSize(this.data.org.address, 50)
        
        for (var i = 0, length = splitText.length; i < length; i++) {
        // loop thru each line and increase
        doc.text(10, y, splitText[i]);
        y+=5;
        }
      }
  
      
    }
    
    if(this.data.org.phone != null){
      doc.text(10,y, "Phone: " + this.data.org.phone);
      y+=5;
    }
  
    //buyer
    y+=10;
    doc.text(10, y, "Client");
    y+=5;
    doc.setFontType('bold');
    doc.text(10, y, this.data.patient.name);
    doc.setFontType('normal');
    y+=5;
  
    if(this.data.patient.mobile1 != null){
      doc.text(10,y, this.data.patient.mobile1);
      y+=5;
    }
  
    
    
    //right side of header
    doc.setFontType('bold');
    doc.text(135, yr, "Receipt No.");
    doc.text(169, yr, "Dated");
    doc.setFontType('normal');
    yr+=5;
    doc.text(135, yr, receipt.receipt_no.toString());
    doc.text(169, yr, receipt.date);
    
    yr+=10;
    doc.setFontType('bold');
    
    
    doc.text(169, yr, "Mode of Payment");
    doc.setFontType('normal');
    yr+=5;
  
    
    
    doc.text(169, yr, receipt.payment_mode);
  
    //particulars
  
    y+=10;
    doc.setFontType('bold');
    doc.text(10,y, "Sl No.");
    doc.text(26, y, "Particulars");
    doc.text(145, y, "HSN/SAC", 'right');
    doc.text(161, y, "Quantity", 'right');
    doc.text(180, y, "Rate", 'right');
    doc.text(199, y, "Amount", 'right');
    
  
    doc.setFontType('normal');
    y+=7;
    var counter = 1;
    doc.text(10,y, counter.toString());
    doc.text(26, y, "Advance payment");
    doc.text(145, y, "", 'right' );
    doc.text(161, y, "1", 'right' );
    doc.text(180, y, receipt.amount.toString(), 'right');
    doc.text(199, y, receipt.amount.toString(), 'right');
  
    
    
    
    
  
    //Total;
  
    doc.setFontType('bold');
    y+=20;
    doc.text(151, y, "Total", 'right');
    doc.setFontSize(10);
    doc.text(199, y, this.data.org.short_c +" "+receipt.amount.toString(), 'right');
  
   
  
    
  
    
  
  
  
    doc.setFontType('normal');
    doc.setFontSize(8);
  
    y+=10;
    doc.text(10, y, "Amount paid in advance (in words)");
    y+=5;
    doc.setFontSize(9);
    doc.setFontType('bold');
    //@ts-ignore
    doc.text(10, y, this.data.org.long_c+" "+number2words(receipt.amount)+" Only ("+this.data.org.short_c+ " " + receipt.amount.toString() + ")"  );
    //@ts-ignore
    
  
    
  
    
  
    
  
    
  
    //output
    var string = doc.output('datauristring');
    var newWindow = window.open("", "PDF", 'dependent=yes,locationbar=no,scrollbars=no,menubar=no,resizable,screenX=50,screenY=50,width=850,height=800');
    newWindow.document.write(
    '<html><body><center>' +
    
    '</center><br>' +
    '<object width=100% height=100% type="application/pdf" data="'+string+'">' +
    '<embed type="application/pdf" src="'+string+'" id="embed_pdf"></embed>' +
    '</object></body></html>');
    newWindow.window.focus();
  
    }

}
