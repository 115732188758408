import { Component,  OnInit, ViewChild } from '@angular/core';
import { DataService } from '../../data.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-add-remarks',
  templateUrl: './add-remarks.component.html',
  styleUrls: ['../patient-profile/patient-profile.component.scss', './add-remarks.component.scss']
})
export class AddRemarksComponent implements OnInit {
  @ViewChild('acInput') acInput;
  id = '0';
  selectedTitle:any = "";
  selectedKey:any = "";
  selectedIndex:any = 0;
  sdId:any = '0';
  note:any = ""; // Update value for every keystroke
  noteHtml:any = ""; //Update only when API response arrives
  aiGenerationStatus:any = false;
  tags:any = [];
  tagSearchKeyWord:any = "";
  suggestions:any = [];
  maxWords:any = 120;
  tagInput:any = "";
  title:any = "Symptoms & Diagnosis";

  data:any = [
     {key: "neurological_examination", title:"Relevant History", note:""}, 
     {key: "developmental_observation", title:"Developmental Observation", note:""},
     {key: "goals", title:"Physical Examination", note:""}, //phy exam
     {key: "intervention", title:"Plan", note:""}, //plan
     {key: "impression", title:"Impression", note:""},
     {key: "others", title:"Others", note:""},
    
  ]

  constructor(private router:Router, private route: ActivatedRoute, private dataService: DataService) {
    this.router.routeReuseStrategy.shouldReuseRoute = function() {
      return false;
    };
  }

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id');
    this.sdId = this.route.snapshot.paramMap.get('sdid');
    
    if(this.sdId != 0){
      this.loadSd();
    } else {
      this.loadDataPoints();  
    }

  }

  loadSd(){
    let context = this;
    this.dataService.getClientSd(this.sdId ).subscribe( (data:any = {status:0, data:[]} )=> {
      if(data.status == 0){
        this.data = data.data;
        this.title = data.title;
      }
    });
  }

  loadDataPoints(){
    this.dataService.getSdDataPoints().subscribe( (data:any = {status:0, data:[]} )=> {
      if(data.status){
        this.data = data.data;
        this.title = data.title;
      }
    });
  }

  selectItem(item, key, index){
    this.selectedTitle = item;
    this.selectedKey = key;
    this.selectedIndex = index;
    this.note = this.data[index].note;
    this.tags = [];
    
  }

  submitNotes(data){
    data.patient_id = this.id;
    data.title = this.selectedTitle;
    data.sdId = this.sdId;
    data.key = this.selectedKey;
    data.note_contents = this.note;

    
    this.dataService.postClientSd(data).subscribe( (res:any) => {
      if(res.status == 0) {
        $('#modalRemarks').modal('hide');
        $('.btn-cancel-remarks').click();
        this.sdId = res.sdId;
        this.data[this.selectedIndex].note = res.note;
        //let elem = this.data.find( (e) => e.key = res.key );
        //elem.note = res.note;
        
      } else {
        alert(res.msg);
      }


    }); 

  }

  saveRemarks(){
    alert("Remarks saved");
  }

  generateNote(){
    

    if(this.tags.length > 0){
      let data = {
        tags:this.tags,
        patient_id:this.id,
        title:this.selectedTitle,
        maxWords:this.maxWords
      }

      this.aiGenerationStatus = true;
      this.dataService.postGenerateSdNote(data).subscribe( (res:any) => {
        this.aiGenerationStatus = false;
        if(res.status == 0) {
          this.note = res.body;
          this.noteHtml = this.note;
          //this.data[this.selectedIndex].note = res.note;
          //let elem = this.data.find( (e) => e.key = res.key );
          //elem.note = res.note;
          
        } else {
          alert(res.msg);
        }


      }); 

    } else {
      alert("Enter/pick at least one tag");
    }


    



  }

  /*updateNote(val){
    this.note = val.innerText;
    
    let lastElement = this.getLastElement(this.note);
    if(lastElement.length >= 5){
      this.dataService.getFormTextSuggestion(lastElement, this.selectedTitle).subscribe( (data:any )=> {
        
        if(data.status == 0){
          //this.noteHtml = val.innerText +  "<span style='color: #767676;'>"+data.suggestion+"</span>";
          this.insertTextAtCursor(val, data.suggestion);
        }
      });
    }
  } */
 
  getLastElement(inputString, delimiter = ",") {
    var arrayOfElements = inputString.split(delimiter);
    var lastElement = arrayOfElements[arrayOfElements.length - 1];
    return lastElement;
  }


  insertTextAtCursor(contentEditableElement, textToInsert) {
    var range, node;
    
    if (window.getSelection && window.getSelection().getRangeAt) {
        range = window.getSelection().getRangeAt(0);
        node = range.createContextualFragment(textToInsert);
        range.insertNode(node);
    //@ts-ignore
    } else if (document.selection && document.selection.createRange) {
        //@ts-ignore
        document.selection.createRange().pasteHTML(textToInsert);
    }

    this.note = contentEditableElement.innerText;

  }


  removeTagByIndex(index) {
    // Check if the index is valid
    if (index >= 0 && index < this.tags.length) {
      // Use splice to remove the element at the specified index
      this.tags.splice(index, 1);
    } else {
      console.error("Invalid index");
    }
  }
  
  /* Autocomplete */

  

  selectSuggestionEvent(event){
    
    if (!event || !event.match) {
      console.error("Invalid event object:", event);
      return;
    }
    //add to array
    this.tags.push(event.match);
    this.acInput.clear();
    this.acInput.focus();
    this.suggestions = [];
    console.log("hey");
    
  }

  pushToTags(tag){
    this.suggestions = [""];
    //add to array
    this.tags.push(tag);
    this.tagInput = "";
    this.onChangeSuggestionSearch(" ");
    
    
  }

  
  onClearSuggestionSearch(event){
    //
  }
  
  

  onChangeSuggestionSearch(event){
    console.log(event);
    this.suggestions = [];
    let obj = {match:event};
    this.suggestions.push({match:event});
    if(event.endsWith(',')){
      this.pushToTags(this.removeTrailingComma(event));
      this.suggestions = [];
      this.tagInput = "";
    } else {
      this.dataService.getFormTextSuggestions(event, this.selectedTitle).subscribe( (data: any )=> {
        this.suggestions = data;
        this.suggestions.push({match:event})
        
      });
    }
    

    

    

  }

  
  

  endsWithComma(inputString) {
    // Using endsWith method to check if the string ends with a comma
    return inputString.endsWith(',');
  }

  removeTrailingComma(inputString) {
    // Use a regular expression to remove the trailing comma
    return inputString.replace(/,\s*$/, '');
  }


}
